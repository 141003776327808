.imageWrapper {
    margin-left: 2rem;
    width: 54rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 4rem;
    @apply bg-bpm-white;
    overflow: hidden;
}

.imageWrapper img {
    height: auto;
    margin-right: -1rem;
}

.ddGruop {
    overflow: visible;
    width: 100%;

    :global(.css:focus-within) {
        box-shadow: none;
    }

    :focus,
    :focus-within {
        outline: 0 !important;
        box-shadow: none !important;
    }

    :global(.react-dropdown-select) {
        display: flex !important;
        flex-direction: row;
        padding-bottom: 0.7rem;

        &:hover,
        &:focus,
        &:focus-within {
            border-color: #2A2A2A !important;
        }
    }

    :global(.react-dropdown-select-input) {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        color: #E6F0E9;
    }

    :global(.react-dropdown-select-dropdown) {
        background: #151918;
        border: none;
        padding: 2rem;


        span {
            font-size: 2rem;
            line-height: 2.6rem;
            border-bottom: none;
            padding: 2rem;

            &:hover {
                background: rgba(105, 125, 122, 0.2);
            }

            &:global(.react-dropdown-select-item-selected) {
                background: rgba(105, 125, 122, 0.2);
            }
        }
    }

    input {
        &::placeholder {
            color: #E6F0E9;
            opacity: 0.5;
        }

        margin-left:0;
    }
}

.mainStats {
    width: 100%;
    max-width: 150rem;
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow: hidden;
}

.statsForm {
    margin-left: 0;
}

.statsFormWrapper {
    @apply flex flex-row justify-between;
}

.smallImage {
    width: 71.5rem;
    height: 51.3rem;
    align-self: center;
}

.mainFormClass {
    transition: all 0.3s ease-in-out;
    width: 42rem;

    padding-top: 18rem;
    padding-bottom: 18rem;
}

.main {
    margin: 0 auto;
    max-width: 108rem;
    width: 100%;
    border: 1px solid #2A2A2A;
}

.formWrapper {

    display: flex;
    justify-content: space-between;

    align-items: center;
    padding-left: 9rem;
}

.formWrapper img {
    // width: 100%;
    // max-width: 49vw;
}

.group {
    margin-top: 0rem;
    // overflow: hidden;
    position: relative;
}



.mainFormClass .input {
    display: block;
    width: 100%;
    border: none;
    border: 1px solid #2A2A2A;
    border-radius: 0;
    background: #151515;
    // transition: 0.3s ease-in-out;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #E6F0E9;
    opacity: 0.5;
    padding-left: 4.4rem;
    padding-right: 1.6rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: 1.6rem;
    outline: none;


}

.bar {
    background: rgba(230, 240, 233, 0.2);
    content: '';
    width: 43rem;
    height: 1px;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.bar:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 150%;
    background: #e6f0e9;
    transition: 0.3s ease-in-out;
    transform: translateX(-100%);
}

@media (max-width: 1280px) {
    .mainFormClass {
        margin-left: 5.5% !important;
    }
}

@media (max-width: 942px) {

    .main {
        width: 92vw;
    }

    .mainFormClass {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .imageWrapper {
        height: 24rem;
    }

    .formWrapper {
        display: flex;
        flex-direction: column-reverse;
        justify-content: start;
        height: unset;
        // width: 95vw;
        // margin: 0 auto;
    }

    .imageWrapper,
    .mainFormClass,
    .formWrapper {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .statsFormWrapper {
        justify-content: center !important;
    }

    .mainStats .statsWrapper {
        display: none;
    }

    .mainStats img {
        display: none;
    }
}

@media (max-width: 720px) {
    .wrap {
        align-items: baseline !important;
    }

    .mainFormClass {
        margin-left: 0 !important;
    }

    .formWrapper {
        margin-top: 0 !important;
    }
}