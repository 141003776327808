.main {
    padding: 1.6rem;
    width: 100%;
    margin: 0 auto;
    max-width: 120rem;
}
.wrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.wrapper {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scroll {
    width: max-content;
    display: flex;
}