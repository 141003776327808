.imageWrapper {
  margin-left: 2rem;
}

.imageWrapper img {
  height: auto;
}

.mainStats {
  width: 100%;
  max-width: 150rem;
  margin: 0 auto;
  padding-top: 17rem;
}

.statsForm {
  margin-left: 0;
}

.statsFormWrapper {
  @apply flex flex-row justify-between;
}

.smallImage {
  width: 71.5rem;
  height: 51.3rem;
}

.mainFormClass {
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 43rem;
  margin-left: 12.5%;
  margin-top: 6rem;
}

.main {
  padding-top: 4rem;
  margin: 0 auto;
  max-width: 192rem;
}

.shadow {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 17.5vw;
  background: linear-gradient(
                  275.71deg,
                  #000000 4.52%,
                  rgba(0, 0, 0, 0) 95.45%
  );
}

.formWrapper {
  left: 0;
  width: 100vw;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.formWrapper img {
  right: 0;
  width: 49vw;
  position: fixed;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}

.group {
  margin-top: 0rem;
  overflow: hidden;
  position: relative;
}

.mainFormClass .label {
  position: absolute;
  top: 36px;
  font-weight: 500;
  font-size: 2rem;
  cursor: text;
  transition: 0.25s ease;
  color: rgba(230, 240, 233, 0.9);
}

.mainFormClass .input {
  display: block;
  width: 100%;
  padding-top: 34px;
  border: none;
  border-radius: 0;
  color: rgba(230, 240, 233);
  background: #000000;
  font-size: 26px;
  transition: 0.3s ease-in-out;
}

.mainFormClass .input:not(:placeholder-shown) ~ .label {
  top: 1rem;
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.5);
}
.mainFormClass .input:focus {
  outline: none;
}
.mainFormClass .input:focus ~ .label {
  top: 1rem;
  font-size: 1.4rem;
  color: #e6f0e9;
}
.mainFormClass .input:focus ~ .bar:before {
  transform: translateX(0);
}
.mainFormClass .input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #333 inset;
  -webkit-text-fill-color: white !important;
  background-clip: content-box !important;
  background-color: transparent !important;
}

.bar {
  background: rgba(230, 240, 233, 0.2);
  content: '';
  width: 43rem;
  height: 1px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.bar:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 150%;
  background: #e6f0e9;
  transition: 0.3s ease-in-out;
  transform: translateX(-100%);
}

@media (max-width: 1280px) {
  .mainFormClass {
    margin-left: 5.5% !important;
  }
}

@media (max-width: 1080px) {
  .mainFormClass {
    margin-left: 0% !important;
  }
}
@media (max-width: 942px) {
  .mainImage,
  .shadow {
    display: none;
  }
  .formWrapper {
    display: flex;
    justify-content: center;
    padding: 3rem;
    padding-right: 1rem;
  }
  .mainStats .statsWrapper {
    display: none;
  }
  .mainStats {
    padding: 2rem !important;
    padding-top: 10rem !important;
  }
  .mainStats img {
    display: none;
  }
}