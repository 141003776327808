.imageWrapper {
    margin-left: 2rem;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    flex-direction: column;
}

.imageWrapper img {
    height: auto;
}

.mainStats {
    width: 100%;
    max-width: 150rem;
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow: hidden;
}

.statsForm {
    margin-left: 0;
}

.statsFormWrapper {
    @apply flex flex-row justify-between;
}

.smallImage {
    width: 71.5rem;
    height: 51.3rem;
    align-self: center;
}

.mainFormClass {
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 43rem;
    margin-left: 12.5%;
    z-index: 20;
}

.main {
    margin: 0 auto;
    max-width: 192rem;
}

.formWrapper {

    display: flex;
    justify-content: space-between;
    width: 100vw;
    align-items: center;
}

.formWrapper img {
    width: 100%;
    max-width: 49vw;
}

.group {
    margin-top: 0rem;
    overflow: hidden;
    position: relative;
}

.mainFormClass .label {
    position: absolute;
    top: 36px;
    font-weight: 500;
    font-size: 2rem;
    cursor: text;
    transition: 0.25s ease;
    color: rgba(230, 240, 233, 0.9);
}

.mainFormClass .input {
    display: block;
    width: 100%;
    padding-top: 34px;
    border: none;
    border-radius: 0;
    background: #000000;
    color: rgba(230, 240, 233);
    font-size: 26px;
    transition: 0.3s ease-in-out;
}

.mainFormClass.transparentForm .input {
    background: transparent;
}

.mainFormClass .input:not(:placeholder-shown)~.label {
    top: 1rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.5);
}

.mainFormClass .input:focus {
    outline: none;
}

.mainFormClass .input:focus~.label {
    top: 1rem;
    font-size: 1.4rem;
    color: #e6f0e9;
}

.mainFormClass .input:focus~.bar:before {
    transform: translateX(0);
}

.mainFormClass:not(.transparentForm) .input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #333 inset;
    -webkit-text-fill-color: white !important;
    background-clip: content-box !important;
    background-color: transparent !important;
}

.mainFormClass.transparentForm .input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-background-clip: text !important;
}

/* Checkbox specific styles */
.mainFormClass .checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    /* Adjust as needed */
}

.mainFormClass .checkbox-input {
    appearance: none;
    /* Remove default checkbox styling */
    width: 24px;
    height: 24px;
    border: 2px solid rgba(230, 240, 233, 0.9);
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    position: relative;
}

.mainFormClass .checkbox-input:checked {
    background-color: transparent;
    /* Checked background color */
}

.mainFormClass .checkbox-input:checked::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

.mainFormClass .checkbox-label {
    margin-left: 10px;
    font-size: 2rem;
    color: rgba(230, 240, 233, 0.9);
    cursor: pointer;
    user-select: none;
    /* Prevent text selection */
}

.mainFormClass .checkbox-input:focus {
    outline: none;
    border-color: #e6f0e9;
    /* Focus border color */
}

/* Adjust label positioning for checkbox sections if needed */
.mainFormClass .checkbox-container+.label {
    top: auto;
    /* reset top position */
    position: static;
    /* reset position */
    margin-top: 10px;
    /* add margin if needed */
    font-size: 1.4rem;
    /* adjust font size if needed */
}

@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

.bar {
    background: rgba(230, 240, 233, 0.2);
    content: '';
    width: 43rem;
    height: 1px;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.bar:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 150%;
    background: #e6f0e9;
    transition: 0.3s ease-in-out;
    transform: translateX(-100%);
}

@media (max-width: 1280px) {
    .mainFormClass {
        margin-left: 5.5% !important;
    }
}

@media (max-width: 942px) {

    .imageWrapper,
    .mainImage,
    .shadow {
        display: none;
    }

    .formWrapper {
        display: flex;
        justify-content: center;
        padding: 3rem;
        margin-top: 6rem;
    }

    .statsFormWrapper {
        justify-content: center !important;
    }

    .mainStats .statsWrapper {
        display: none;
    }

    .mainStats img {
        display: none;
    }
}

@media (max-width: 720px) {
    .wrap {
        align-items: baseline !important;
    }

    .mainFormClass {
        margin-left: 0 !important;
    }

    .formWrapper {
        margin-top: 0 !important;
    }
}