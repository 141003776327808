.social-share {
    position: relative;

    &__toggle {
        align-items: center;
        column-gap: 4px;
        display: flex;
        font: 500 1.6rem/2.4rem gerstner_programm_fslmedium;
        justify-content: center;
        letter-spacing: .35px;

        >svg>path {
            fill: currentColor;
        }
    }

    &__menu {
        background: var(--color-cod-gray-300);
        display: flex;
        filter: drop-shadow(0 8px 24px rgb(18 18 18 / 75%));
        flex-direction: column;
        opacity: 0;
        position: absolute;
        right: -16px;
        top: calc(100% + 16px);
        transition: opacity .2s, visibility .2s;
        visibility: hidden;
        z-index: 2;

        .social-share--open & {
            opacity: 1;
            visibility: visible;
        }
    }

    &__menu-item {
        align-items: center;
        color: var(--color-gray-200);
        column-gap: 8px;
        display: flex;
        flex-wrap: nowrap;
        font: 500 1.4rem/2.4rem gerstner_programm_fslmedium;
        letter-spacing: .1px;
        padding: 8px 16px 8px 8px;
        text-decoration: none;
        white-space: nowrap;
    }
}