
  .group {
    margin-top: 0rem;
    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;
  }
  
  .mainFormClass .label {
    position: absolute;
    top: 2rem;
    font-weight: 500;
    font-size: 2rem;
    cursor: text;
    transition: 0.25s ease;
    color: #000000;
  }
  
  .mainFormClass .input {
    display: block;
    width: 100%;
    padding-top: 2.5rem;
    border: none;
    border-radius: 0;
    color: #000000;
    background: transparent;
    font-size: 2rem;
    transition: 0.3s ease-in-out;
  }
  
  .mainFormClass .input:not(:placeholder-shown) ~ .label {
    top: 1rem;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.5);
  }
  .mainFormClass .input:focus {
    outline: none;
  }
  .mainFormClass .input:focus ~ .label {
    top: 1rem;
    font-size: 1.4rem;
    color: #000000;
  }
  .mainFormClass .input:focus ~ .bar:before {
    transform: translateX(0);
  }
  .mainFormClass .input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #333 inset;
    -webkit-text-fill-color: black !important;
    background-clip: content-box !important;
    background-color: transparent !important;
  }
  
  .bar {
    background: rgba(230, 240, 233, 0.2);
    content: '';
    width: 43rem;
    height: 1px;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  .bar:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 150%;
    background: #000000;
    transition: 0.3s ease-in-out;
    // transform: translateX(-100%);
  }

